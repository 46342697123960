<script setup>
import {
  definePageMeta,
  computed,
  reactive,
  defineAsyncComponent,
  ref,
  useRuntimeConfig,
  onBeforeUnmount,
  onMounted,
  watch,
} from '#imports';
import { object, string } from 'yup';
import { Form as VeeForm } from 'vee-validate';
import { useRoute, useRouter } from 'nuxt/app';
import { useModal } from 'vue-final-modal';

import { useUserStore } from '@/stores/user';
import { useAuthStore } from '@/stores/auth';

import { formatValues } from '@/utils/helpers';
import { useFormHelp } from '@/composables/useFormHelp';

import { useSeo } from '@/composables/useSeo';
import { useWaitRequest } from '@/composables/useWaitRequest';
import { useMessagePopup } from '@/composables/useMessagePopup';

import UiMaskInput from '@/components/ui/form/UiMaskInput.vue';
import UiButton from '@/components/ui/button/UiButton.vue';
import UiButtonIcon from '@/components/ui/button/UiButtonIcon.vue';
import UiInput from '@/components/ui/form/UiInput.vue';
import FormLayout from '@/components/layout/forms/FormLayout.vue';
import AuthLayout from '@/components/layout/AuthLayout.vue';
import { YandexSmartCaptcha } from '@gladesinger/vue3-yandex-smartcaptcha';

import { USER_ROLES } from '@/configs/user-roles';

definePageMeta({
  middleware: ['is-authorized'],
});

useSeo({ title: 'Авторизация | Discovermore.ru' });

const userStore = useUserStore();
const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();
const formHelpComposable = useFormHelp();
const { requestInProgress, waitRequest } = useWaitRequest();
const yaCaptchaRef = ref(null);
const configRuntime = useRuntimeConfig();
const { openMessagePopup } = useMessagePopup();

watch(
  route,
  (routeValue) => {
    const { query } = routeValue;

    if (process.client && query['direct-crm-ticket']) {
      waitRequest(() =>
        authStore
          .sendLoginByTicket({
            ticket: query['direct-crm-ticket'],
          })
          .then(() => {
            const pathRedirect = route.query?.authRedirect;
            const query = { ...route.query };

            if (pathRedirect) {
              router.push({ path: pathRedirect.toString(), query });

              return;
            }

            router.push('/');
          })
          .catch(() => {
            openMessagePopup({
              text: 'Время действия ссылки истекло. Для продолжения работы с сайтом, пожалуйста, авторизуйтесь заново.',
              isSuccess: false,
            });
          }),
      );
    }
  },
  { immediate: true },
);

let formActions = null;
function openRestorePasswordPopup() {
  const { open, close } = useModal({
    modalId: 'restorePasswordPopup',
    component: defineAsyncComponent(
      () => import('@/components/popups/RestorePassword.vue'),
    ),
    attrs: {
      closeModal: () => {
        close();
      },
    },
  });

  open();
}

// <editor-fold desc="FORM">
const formData = reactive({
  phone: '',
  password: '',
});

const phoneData = computed({
  get() {
    return formData.phone;
  },
  set(detail = {}) {
    formData.phone = detail.masked;
  },
});
function sendForm(_, actions) {
  formActions = actions;

  yaCaptchaRef.value.execute();
}
// </editor-fold>

//<editor-fold desc="Validation rules">
const validationSchema = object({
  phone: string().required().trim().lengthPhone(11),
  password: string().required(),
});
// </editor-fold>
const success = (token) => {
  const data = {
    password: formData.password,
    phone: formatValues.phoneOnlyNumbers(formData.phone),
    captcha: token,
  };

  yaCaptchaRef.value.reset();

  waitRequest(() =>
    authStore
      .sendLogin(data)
      .then(() => {
        userStore.fetchUser().then((data) => {
          const role = data?.role;

          if (role === USER_ROLES.NEW) {
            router.push('/auth/registration');
          } else {
            router.push('/');
          }

          sendAnalyticLoginSuccess();
        });
      })
      .catch(({ data }) => {
        sendAnalyticLoginError();

        formHelpComposable.checkErrors(data, formActions.setErrors);
      }),
  );
};

onBeforeUnmount(() => {
  // yaCaptchaRef.value.destroy();
});

//<editor-fold desc="ANALYTIC">
import { authAnalytic } from '@/analytic/google/auth';

function sendAnalyticLoginSuccess() {
  authAnalytic.loginSuccess();
}

function sendAnalyticLoginError() {
  authAnalytic.loginError();
}

function sendAnalyticRegistrationStart() {
  authAnalytic.registrationStart();
}

onMounted(() => {
  authAnalytic.loginStart();
});
//</editor-fold>
</script>

<template>
  <AuthLayout class="login-page">
    <template #content>
      <client-only>
        <YandexSmartCaptcha
          ref="yaCaptchaRef"
          :site-key="configRuntime?.public?.recaptchaKey"
          :invisible="true"
          shield-position="bottom-right"
          @on-success="success"
        />
      </client-only>

      <VeeForm
        v-slot="{ isSubmitting }"
        :validation-schema="validationSchema"
        autocomplete="off"
        class="login-page__form"
        @submit="sendForm"
      >
        <FormLayout heading="Вход" class="login-page__wrapper">
          <template #content>
            <UiMaskInput
              v-model="phoneData"
              :mask="'+7 (###) ### ## ##'"
              unmasked
              name="phone"
              label="Телефон"
              placeholder="+7("
              class="login-page__item"
              input-mode="numeric"
            />

            <div class="login-page__item-wrapper">
              <UiInput
                v-model="formData.password"
                name="password"
                label="Пароль"
                placeholder="Пароль"
                :type="'password'"
                is-toggle-password
                class="login-page__item"
                autocomplete="current-password"
                is-error-text-indented
              />

              <UiButtonIcon
                theme="transparent"
                class="login-page__button login-page__button--link"
                text="Забыли пароль?"
                @click="openRestorePasswordPopup"
              />
            </div>
          </template>

          <template #footer>
            <div class="login-page__footer">
              <UiButton
                type="submit"
                class="login-page__button login-page__button--submit"
                text="войти"
                :is-disabled="isSubmitting || requestInProgress"
                @dblclick.prevent
              />

              <div class="login-page__links login-page__links--link">
                <div class="login-page__text">
                  <p class="login-page__font login-page__font--text">
                    Нет аккаунта?
                  </p>
                </div>

                <UiButtonIcon
                  theme="transparent"
                  to="/auth/registration"
                  class="login-page__button login-page__button--registration"
                  text="Зарегистрироваться"
                  @click="sendAnalyticRegistrationStart"
                />
              </div>
            </div>
          </template>
        </FormLayout>
      </VeeForm>
    </template>
  </AuthLayout>
</template>

<style scoped lang="scss">
.login-page {
  $parent: &;

  &__font {
    &--text {
      font-size: em(14);
      font-weight: 600;
      line-height: 1;
      color: $color-grey-3;
    }
  }

  &__form {
    width: em(800);

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__wrapper {
    max-height: calc(100vh - #{em(116)});

    @include media-breakpoint-down(sm) {
      max-height: none;
    }
  }

  &__item-wrapper {
    position: relative;
    padding-bottom: em(20);
  }

  &__button {
    &--submit {
      width: 100%;
    }

    &--link {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: em(16);
  }

  &__links {
    &--link {
      display: flex;
      gap: em(8);
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
